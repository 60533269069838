import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { compact } from "lodash";
import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import LoadingPage from "../../../layout/LoadingPage";
import { RouteNames } from "../../../routes/routeNames";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavigationBar from "../components/NavBar";
import Approach from "../components/content/Approach";
import Privacy from "../components/content/Privacy";
import Steps from "../components/content/Steps";
import useOrganizationForLandingPage from "../hooks/useOrganizationForLandingPage";

const PREFIX = "OrganizationLandingPage";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
  },
}));

const OrganizationLandingPage = () => {
  const [searchParams] = useSearchParams();
  const emailOrCode = searchParams.get("email-or-code");

  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
  ]);

  const selectTranslation = useSelectTranslation();
  const { trackPageView } = useMatomo();
  const organization = useOrganizationForLandingPage({
    emailOrCode,
  });

  useEffect(() => {
    if (organization)
      trackPageView({
        customDimensions: [
          {
            id: 2,
            value: organization.id.toString(),
          },
        ],
        documentTitle: "Organization landing index",
      });
  }, [organization, selectTranslation, trackPageView]);

  if (!organization) return <LoadingPage full={false} />;

  const {
    color,
    logoUrl,
    ggtEnabled,
    videoEnabled,
    mindlabEnabled,
    name,
    consultsPerHuman,
    ssoEnabled,
  } = organization;

  const nameForTranslation = selectTranslation(name);
  const organizationName = !nameForTranslation
    ? t("organization:landing.noOrganizationName")
    : nameForTranslation;

  const baseUrl = "/employee";

  return (
    <StyledBox className={classes.root}>
      <NavigationBar
        baseUrl={baseUrl}
        navigation={compact([
          ggtEnabled && {
            label: t("organization:landing.navigation.ggt"),
            path: `${baseUrl}${
              RouteNames.NewB2BLandingPages.ggt.path
            }/?${searchParams.toString()}`,
          },
          mindlabEnabled && {
            label: t("organization:landing.navigation.selfcare"),
            path: `${baseUrl}${
              RouteNames.NewB2BLandingPages.selfCare.path
            }/?${searchParams.toString()}`,
          },
          videoEnabled && {
            label: t("organization:landing.navigation.video"),
            path: `/employee/video/?${searchParams.toString()}`,
          },
        ])}
        organizationColor={color ?? "#15262A"}
        organizationLogoUrl={selectTranslation(logoUrl)}
        organizationName={selectTranslation(organization.name)}
      />
      <Header
        headerImageUrl="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/eating+smiling+girl.jpg"
        text={
          ggtEnabled && videoEnabled
            ? t("organization:landing.header.text")
            : t("organization:landing.header.textSingleOffering")
        }
        title={t("organization:landing.header.title")}
      />
      <Box height={"3rem"} />
      <Approach
        baseUrl={baseUrl}
        consultsPerHuman={consultsPerHuman}
        ggtEnabled={ggtEnabled}
        mainTitle={t("organization:landing.approach.mainTitle")}
        mindlabEnabled={mindlabEnabled}
        name={organizationName}
        videoEnabled={videoEnabled}
      />
      <Box height={"3rem"} />
      <Privacy organizationName={organizationName} />
      <Box height={"3rem"} />
      <Steps
        mainText={t("organization:landing.represent.text")}
        mainTitle={t("organization:landing.represent.title")}
        steps={[
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+Calendar.svg",
            text: t("organization:landing.represent.steps.1.text"),
            title: t("organization:landing.represent.steps.1.title"),
          },
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+Hurdle+Running.svg",
            text: t("organization:landing.represent.steps.2.text"),
            title: t("organization:landing.represent.steps.2.title"),
          },
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+Mirror.svg",
            text: t("organization:landing.represent.steps.3.text"),
            title: t("organization:landing.represent.steps.3.title"),
          },
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+Sparks.svg",
            text: t(
              "organization:landing.represent.steps.4.text",
              "Onze wetenschappelijke methodes zijn uitgewerkt om blijvende veranderingen teweeg te brengen. Zo bereik je wijzigingen in je gedrag en denken die levenslang voordelen meedragen.",
            ),
            title: t(
              "organization:landing.represent.steps.4.title",
              "Verandering die blijft",
            ),
          },
        ]}
      />
      <Box height={"3rem"} />
      <Footer
        baseUrl={baseUrl}
        ggtEnabled={ggtEnabled}
        mindlabBusinessId={organization.mindlabBusinessId || undefined}
        mindlabEnabled={mindlabEnabled}
        ssoEnabled={ssoEnabled}
        videoEnabled={videoEnabled}
      />
    </StyledBox>
  );
};

export default OrganizationLandingPage;
