import { Box, Typography } from "@mui/material";
import Button from "../../ui/buttons/Button";
import { graphql } from "../../../api/__generated__";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useState } from "react";

const topicsQuery = graphql(`
  query Topics {
    topics {
      descriptionTranslationKey
      id
      imageUrl
      titleTranslationKey
    }
  }
`);

const submitTopicStepMutation = graphql(`
  mutation SubmitTopicStep($topicId: String) {
    submitTopicStep(topicId: $topicId) {
      matchingToken
      nextStep
    }
  }
`);

const TopicStep = ({
  setNextStep,
}: {
  setNextStep: (nextStep: string) => void;
}) => {
  const { t } = useTranslation();
  const { data } = useQuery(topicsQuery);
  const [submitTopicStep] = useMutation(submitTopicStepMutation);
  const [selectedTopicId, setSelectedTopicId] = useState<string | null>();

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> TOPIC </Typography>
      {data?.topics.map((topic) => (
        <Fragment key={topic.id}>
          <Box sx={{ border: "1px solid #000" }}>
            <Box component="img" src={topic.imageUrl} />
            <Typography>{t(topic.descriptionTranslationKey)}</Typography>
            <Button
              label={t(topic.titleTranslationKey)}
              onClick={() => {
                setSelectedTopicId(topic.id);
              }}
            />
          </Box>
        </Fragment>
      ))}
      <Box sx={{ border: "1px solid #000" }}>
        <Button
          label={"Iets Anders"}
          onClick={() => {
            setSelectedTopicId(null);
          }}
        />
      </Box>
      <br /> <br />
      {selectedTopicId !== undefined && (
        <Button
          label="next"
          onClick={async () => {
            const { data: submitData } = await submitTopicStep({
              variables: {
                topicId: selectedTopicId,
              },
            });

            submitData?.submitTopicStep.nextStep &&
              setNextStep(submitData.submitTopicStep.nextStep);

            submitData?.submitTopicStep.matchingToken &&
              localStorage.setItem(
                "matchingToken",
                submitData.submitTopicStep.matchingToken,
              );
          }}
          type="submit"
        />
      )}
    </Box>
  );
};

export default TopicStep;
