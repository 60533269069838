import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WaveBox } from "wave-box";
import { compact } from "lodash";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import useStartMatching from "../../../../api/matching/hooks/useStartMatching";
import useStorage from "../../../hooks/useStorage";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import LoadingPage from "../../../layout/LoadingPage";
import { RouteNames } from "../../../routes/routeNames";
import Button from "../../../ui/buttons/Button";
import HumanMatchingContext from "../../human-matching/context/HumanMatchingContext";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavigationBar from "../components/NavBar";
import Steps from "../components/content/Steps";
import useOrganizationForLandingPage from "../hooks/useOrganizationForLandingPage";
import Organization from "../../../../data-model/types/Organization";
import environment from "../../../../environment/environment";

const PREFIX = "VideoLandingPage";

const classes = {
  ResultsPromisesContentItem: `${PREFIX}-ResultsPromisesContentItem`,
  allMoods: `${PREFIX}-allMoods`,
  containerAllMoods: `${PREFIX}-containerAllMoods`,
  containerResultsPromises: `${PREFIX}-containerResultsPromises`,
  containerResultsPromisesContent: `${PREFIX}-containerResultsPromisesContent`,
  imageResultsPromises: `${PREFIX}-imageResultsPromises`,
  mainTitleResultsPromises: `${PREFIX}-mainTitleResultsPromises`,
  moodLabel: `${PREFIX}-moodLabel`,
  root: `${PREFIX}-root`,
  textResultsPromises: `${PREFIX}-textResultsPromises`,
  textStyle: `${PREFIX}-textStyle`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.containerAllMoods}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 96px",
    width: "100%",
    [theme.breakpoints.down(900)]: {
      padding: "0 6rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "0 3rem",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0 2rem",
    },
    [theme.breakpoints.down(280)]: {
      padding: "0 1rem",
    },
  },

  [`& .${classes.allMoods}`]: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    justifyContent: "space-evenly",
    width: "100%",
    [theme.breakpoints.down(900)]: {
      gridTemplateColumns: "auto auto",
    },
    [theme.breakpoints.down(490)]: {
      gridTemplateColumns: "auto",
    },
  },

  [`& .${classes.moodLabel}`]: {
    "& .Mui-checked img": {
      filter: "grayscale(0%)",
    },
    "& img": {
      filter: "grayscale(100%)",
    },
    "&:hover": { color: "#F0C82B" },
    "&:hover img": {
      filter: "grayscale(0%)",
    },
    marginLeft: 0,
    marginRight: 0,
  },

  [`& .${classes.textStyle}`]: {
    fontWeight: 700,
  },

  [`& .${classes.textResultsPromises}`]: {
    fontWeight: 900,
  },

  [`& .${classes.containerResultsPromises}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0 96px",
    textAlign: "center",
    [theme.breakpoints.down(900)]: {
      padding: "0 6rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "0 3rem",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0 2rem",
    },
    [theme.breakpoints.down(280)]: {
      padding: "0 1rem",
    },
  },

  [`& .${classes.containerResultsPromisesContent}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: "1000px",
    width: "100%",
    [theme.breakpoints.down(745)]: {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  [`& .${classes.ResultsPromisesContentItem}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: "18px",
    margin: "24px",
    textAlign: "center",
    width: "150px",
  },

  [`& .${classes.mainTitleResultsPromises}`]: {
    marginBottom: "48px",
  },

  [`& .${classes.imageResultsPromises}`]: {
    height: "auto",
    marginBottom: "24px",
    maxWidth: "80%",
  },
}));

const possibleMoods = [
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/dejected_face_aecee6b841.svg",
    translationKey: "organization:landingVideo.moods.lonely",
  },
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/smiling_face_with_smiling_eyes_ce0d50c683.svg",
    translationKey: "organization:landingVideo.moods.happy",
  },
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/star_struck_bf8330307c.svg",
    translationKey: "organization:landingVideo.moods.motivated",
  },
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/exploding_head_12184d8104.svg",
    translationKey: "organization:landingVideo.moods.stressed",
  },
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/slightly_frowning_face_e7f569c28d.svg",
    translationKey: "organization:landingVideo.moods.insecure",
  },
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/face_with_spiral_eyes_42d97345af.svg",
    translationKey: "organization:landingVideo.moods.panicked",
  },
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/crying_face_c4f81705c1.svg",
    translationKey: "organization:landingVideo.moods.sad",
  },
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/face_with_rolling_eyes_a328a8d675.svg",
    translationKey: "organization:landingVideo.moods.desired",
  },
  {
    imageUrl:
      "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/weary_face_e8dd8567d9.svg",
    translationKey: "organization:landingVideo.moods.tired",
  },
];

const VideoLandingPage = () => {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
  ]);

  const smallScreen = useMediaQuery("(max-width: 500px)");
  const selectTranslation = useSelectTranslation();

  const navigate = useNavigate();
  const { removeMatchingTokenFromStorage, persistMatchingTokenToStorage } =
    useStorage();

  const [searchParams] = useSearchParams();
  const { setOrganization } = useContext(HumanMatchingContext);

  const { startMatching } = useStartMatching();

  const { pathname } = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const organization = useOrganizationForLandingPage({
    emailOrCode: searchParams.get("email-or-code"),
  });

  const [selectedMoods, setSelectedMoods] = useState(new Set<string>());

  const handleMatchingPress = useCallback(async () => {
    await removeMatchingTokenFromStorage();
    setOrganization(new Organization(organization ?? undefined));

    if (organization?.ssoEnabled) {
      navigate(
        `${
          RouteNames.Login.path + RouteNames.Login.Sso.path
        }?redirectUrl=${encodeURI(
          environment.REACT_APP_URL + "/matching/start",
        )}`,
      );
    } else {
      const startedMatch = await startMatching({
        slug: organization?.slug,
      });

      if (startedMatch) {
        await persistMatchingTokenToStorage(startedMatch.matchingToken);
        navigate(RouteNames.Matching.Consent.path);
      }
    }
  }, [
    navigate,
    organization,
    persistMatchingTokenToStorage,
    removeMatchingTokenFromStorage,
    setOrganization,
    startMatching,
  ]);

  useEffect(() => {
    if (!organization) return;

    if (!organization.videoEnabled)
      navigate("/employee/?" + searchParams.toString());
  }, [navigate, organization, searchParams]);

  useEffect(() => {
    if (organization)
      trackPageView({
        customDimensions: [
          {
            id: 2,
            value: organization.id.toString(),
          },
        ],
        documentTitle: "Organization landing index",
      });
  }, [organization, selectTranslation, trackPageView]);

  if (!organization) return <LoadingPage full={false} />;

  const {
    color,
    logoUrl,
    ggtEnabled,
    mindlabEnabled,
    videoEnabled,
    ssoEnabled,
  } = organization;

  const baseUrl = "/employee";

  return (
    <StyledBox className={classes.root}>
      <NavigationBar
        baseUrl={baseUrl}
        loginUrl={ssoEnabled ? "/login/sso" : "/login"}
        navigation={compact([
          ggtEnabled && {
            label: t("organization:landing.navigation.ggt"),
            path: `${baseUrl}${
              RouteNames.NewB2BLandingPages.ggt.path
            }/?${searchParams.toString()}`,
          },
          mindlabEnabled && {
            label: t("organization:landing.navigation.selfcare"),
            path: `${baseUrl}${
              RouteNames.NewB2BLandingPages.selfCare.path
            }/?${searchParams.toString()}`,
          },
          videoEnabled && {
            active: true,
            label: t("organization:landing.navigation.video"),
            path: `${baseUrl}/video/?${searchParams.toString()}`,
          },
        ])}
        organizationColor={color ?? "#15262A"}
        organizationLogoUrl={selectTranslation(logoUrl)}
        organizationName={selectTranslation(organization.name)}
      />
      <Header
        backgroundColor="white"
        button={{
          color: "green",
          label: t("organization:landingVideo.header.button"),
          onClick: handleMatchingPress,
        }}
        headerImageUrl="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Frame+2.png"
        text={t("organization:landingVideo.header.text")}
        title={t("organization:landingVideo.header.title")}
      />
      <Box height={"3rem"} />
      <Box className={classes.containerResultsPromises}>
        <Box className={classes.containerResultsPromisesContent}>
          <Box className={classes.ResultsPromisesContentItem}>
            <img
              alt="Comfort"
              className={classes.imageResultsPromises}
              src={
                "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Comfort.svg"
              }
            ></img>
            <Typography className={classes.textResultsPromises}>
              {t("organization:landingVideo.resultsPromises.tailored")}
            </Typography>
          </Box>
          <Box className={classes.ResultsPromisesContentItem}>
            <img
              alt="Bullseye"
              className={classes.imageResultsPromises}
              src={
                "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Behind+laptop+green.svg"
              }
            ></img>
            <Typography className={classes.textResultsPromises}>
              {t("organization:landingVideo.resultsPromises.deepConnection")}
            </Typography>
          </Box>
          <Box className={classes.ResultsPromisesContentItem}>
            <img
              alt="Sitting behind a laptop"
              className={classes.imageResultsPromises}
              src={
                "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Calendar.svg"
              }
            ></img>
            <Typography className={classes.textResultsPromises}>
              {t("organization:landingVideo.resultsPromises.coaching")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <WaveBox background="#FBF5F0" fillBackground waveHeight="100px">
        <Steps
          mainText={t("organization:landingVideo.steps.text")}
          mainTitle={t("organization:landingVideo.steps.title")}
          steps={[
            {
              imageUrl:
                "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Gender+choice.svg",
              text: t("organization:landingVideo.steps.steps.1.text"),
              title: t("organization:landingVideo.steps.step.1.title"),
            },
            {
              imageUrl:
                "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/You+got+a+match.svg",
              text: t("organization:landingVideo.steps.step.2.text"),
              title: t("organization:landingVideo.steps.step.2.title"),
            },
            {
              imageUrl:
                "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Free+consultation.svg",
              text: t("organization:landingVideo.steps.step.3.text"),
              title: t("organization:landingVideo.steps.step.3.title"),
            },
            {
              imageUrl:
                "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Videocall.svg",
              text: t("organization:landingVideo.steps.step.4.text"),
              title: t("organization:landingVideo.steps.step.4.title"),
            },
          ]}
        />
      </WaveBox>
      <Box height={"3rem"} />
      <Box className={classes.containerAllMoods}>
        <Typography variant={smallScreen ? "h2" : "h1"}>
          {t("organization:landingGgt.workTogether.mainTitle")}
        </Typography>
        <Typography>
          {t("organization:landingGgt.workTogether.mainText")}
        </Typography>
        <FormGroup className={classes.allMoods}>
          {possibleMoods.map(({ translationKey, imageUrl }) => (
            <FormControlLabel
              className={classes.moodLabel}
              classes={{ label: classes.textStyle }}
              control={
                <Checkbox
                  checked={selectedMoods.has(translationKey)}
                  checkedIcon={
                    <img
                      alt="oneMoodImage"
                      height={100}
                      src={imageUrl}
                      width={100}
                    />
                  }
                  icon={
                    <img
                      alt="oneMoodImage"
                      height={100}
                      src={imageUrl}
                      width={100}
                    />
                  }
                  name={t(translationKey) ?? undefined}
                  onChange={({ target }) => {
                    setSelectedMoods((current) => {
                      const newSet = new Set(current);

                      if (target.checked) {
                        newSet.add(translationKey);
                      } else {
                        newSet.delete(translationKey);
                      }

                      return newSet;
                    });
                  }}
                  value={translationKey}
                />
              }
              key={translationKey}
              label={t(translationKey)}
            />
          ))}
        </FormGroup>
        <Button
          disabled={selectedMoods.size === 0}
          fullyColored
          label={t("organization:landingVideo.startMatchingButton")}
          onClick={handleMatchingPress}
          style={{ margin: 32 }}
        />
      </Box>
      <Footer
        baseUrl={baseUrl}
        ggtEnabled={ggtEnabled}
        mindlabBusinessId={organization.mindlabBusinessId || undefined}
        mindlabEnabled={mindlabEnabled}
        ssoEnabled={ssoEnabled}
        videoEnabled={videoEnabled}
      />
    </StyledBox>
  );
};

export default VideoLandingPage;
