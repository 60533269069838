import { Box, Typography } from "@mui/material";
import Button from "../../ui/buttons/Button";
import { graphql } from "../../../api/__generated__";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useState } from "react";

const selectedTopicQuery = graphql(`
  query SelectedTopic {
    selectedTopic {
      id
      subTopics {
        descriptionTranslationKey
        id
        imageUrl
        titleTranslationKey
      }
    }
  }
`);

const submitSubTopicStepMutation = graphql(`
  mutation SubmitSubTopicStep($subTopicId: String) {
    submitSubTopicStep(subTopicId: $subTopicId) {
      matchingToken
      nextStep
    }
  }
`);

const SubTopicStep = ({
  setNextStep,
}: {
  setNextStep: (nextStep: string) => void;
}) => {
  const { t } = useTranslation();
  const { data } = useQuery(selectedTopicQuery);
  const [submitSubTopicStep] = useMutation(submitSubTopicStepMutation);
  const [selectedSubTopicId, setSelectedSubTopicId] = useState<string | null>();

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> SUBTOPIC </Typography>
      {data?.selectedTopic?.subTopics.map((subTopic) => (
        <Fragment key={subTopic.id}>
          <Box sx={{ border: "1px solid #000" }}>
            <Box component="img" src={subTopic.imageUrl} />
            <Typography>{t(subTopic.descriptionTranslationKey)}</Typography>
            <Button
              label={t(subTopic.titleTranslationKey)}
              onClick={() => {
                setSelectedSubTopicId(subTopic.id);
              }}
            />
          </Box>
        </Fragment>
      ))}
      <Box sx={{ border: "1px solid #000" }}>
        <Button
          label={"Iets Anders"}
          onClick={() => {
            setSelectedSubTopicId(null);
          }}
        />
      </Box>
      <br /> <br />
      {selectedSubTopicId !== undefined && (
        <Button
          label="next"
          onClick={async () => {
            const { data: submitData } = await submitSubTopicStep({
              variables: {
                subTopicId: selectedSubTopicId,
              },
            });

            submitData?.submitSubTopicStep.nextStep &&
              setNextStep(submitData.submitSubTopicStep.nextStep);

            submitData?.submitSubTopicStep.matchingToken &&
              localStorage.setItem(
                "matchingToken",
                submitData.submitSubTopicStep.matchingToken,
              );
          }}
          type="submit"
        />
      )}
    </Box>
  );
};

export default SubTopicStep;
